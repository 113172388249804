import { apiPatch } from 'src/shared/services/api/apiBase';
import { PatchUser } from '../../domain/model/user';
import { EditUserRequestDto } from './userDto';

export default async function patchUserToApi(id: number, { organization, role }: PatchUser): Promise<void> {
  const body: EditUserRequestDto = {
    organization,
    role,
  };

  await apiPatch(`auth/users/${id}`, body);
}

import { apiGet } from 'src/shared/services/api/apiBase';
import { LinkerPlatformUser } from '../../domain/model/user';
import { LinkerPlatformUserDto } from './userDto';

function getLinkerPlatformUserFromDto(dto: LinkerPlatformUserDto[]): LinkerPlatformUser[] {
  return dto.map(({ email, first_name, id, last_name }) => ({
    email,
    firstName: first_name,
    id,
    lastName: last_name,
  }));
}

export default async function getLinkerPlatformUserListFromApi(email?: string): Promise<LinkerPlatformUser[]> {
  const result = await apiGet<LinkerPlatformUserDto[]>('api/linker-platform/members', {
    ...(email && {
      email,
    }),
  });

  return getLinkerPlatformUserFromDto(result);
}

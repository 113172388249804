import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useUserContext } from 'src/modules/user/services/store/UserContext';
import useSocket from 'src/shared/services/webSocket/useSocket.hook';

const WS_NOTIFICATION_URL = process.env.REACT_APP_WS_NOTIFICATION_URL as string;

type SocketMessageType = { has_unread: boolean };

export interface NotificationContextStore {
  eventLogModalId: number | null;
  hasUnread: boolean;
  setEventLogModalId: Dispatch<SetStateAction<number | null>>;
}

export const notificationContextDefaultState: NotificationContextStore = {
  eventLogModalId: null,
  hasUnread: false,
  setEventLogModalId: () => {},
};

const NotificationContext = createContext<NotificationContextStore>(notificationContextDefaultState);

export default NotificationContext;

export function useNotificationContext(): NotificationContextStore {
  const { eventLogModalId, hasUnread, setEventLogModalId } = useContext(NotificationContext);

  return { eventLogModalId, hasUnread, setEventLogModalId };
}

export function useNotificationContextValue(): NotificationContextStore {
  const { userProfile } = useUserContext();
  const [hasUnread, setHasUnread] = useState<boolean>(userProfile?.hasUnread ?? false);
  const [eventLogModalId, setEventLogModalId] = useState<number | null>(null);

  const { message: { has_unread: hasUnreadFromWs } = {} } = useSocket<SocketMessageType>(WS_NOTIFICATION_URL);

  useEffect(() => {
    if (hasUnreadFromWs !== undefined) {
      setHasUnread(hasUnreadFromWs);
    }
  }, [hasUnreadFromWs]);

  useEffect(() => {
    if (userProfile && userProfile.email !== '') {
      setHasUnread(userProfile.hasUnread);
    }
  }, [userProfile]);

  const contextValue: NotificationContextStore = useMemo(
    () => ({
      eventLogModalId,
      hasUnread,
      setEventLogModalId,
    }),
    [hasUnread, eventLogModalId, setEventLogModalId],
  );

  return contextValue;
}

import { createContext, useContext, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DetectionViewType } from 'src/modules/detection/domain/observ/model/detection';
import { ObservTaskType, TaskViewType, TaskStatus, TaskListItem } from 'src/modules/task/domain/model/task/observTask';
import ALL_SCENARIO_ID from 'src/shared/libs/constants/allScenarioId';
import { OrderByDirection } from 'src/shared/libs/model/orderBy';
import { SortConfig } from 'visionai-baseui';

export interface PageContextStore {
  detectionPage: number;
  detectionViewType: DetectionViewType;
  editModelRepositoryId: number | null;
  isEditModelRepositoryModalOpen: boolean;
  scenarioId: number;
  selectModelRepositoryId: number | null;
  setDetectionPage: (page: number) => void;
  setDetectionViewType: (type: DetectionViewType) => void;
  setEditModelRepositoryId: (id: number | null) => void;
  setIsEditModelRepositoryModalOpen: (value: boolean) => void;
  setScenarioId: (id: number) => void;
  setSelectModelRepositoryId: (id: number | null) => void;
  setSortConfig: Dispatch<SetStateAction<SortConfig<TaskListItem, keyof TaskListItem>>>;
  setTaskPage: (page: number) => void;
  setTaskStatus: Dispatch<SetStateAction<TaskStatus>>;
  setTaskType: (type: ObservTaskType) => void;
  setViewType: (type: TaskViewType) => void;
  sortConfig: {
    direction: OrderByDirection;
    key: keyof TaskListItem;
  };
  taskPage: number;
  taskStatus: TaskStatus;
  taskType: ObservTaskType;
  viewType: TaskViewType;
}

export const PageContextDefaultState: PageContextStore = {
  detectionPage: 0,
  detectionViewType: 'scenario',
  editModelRepositoryId: null,
  isEditModelRepositoryModalOpen: false,
  scenarioId: 0,
  selectModelRepositoryId: 0,
  setDetectionPage: () => {},
  setDetectionViewType: () => {},
  setEditModelRepositoryId: () => {},
  setIsEditModelRepositoryModalOpen: () => {},
  setScenarioId: () => {},
  setSelectModelRepositoryId: () => {},
  setSortConfig: () => {},
  setTaskPage: () => {},
  setTaskStatus: () => {},
  setTaskType: () => {},
  setViewType: () => {},
  sortConfig: {
    direction: 'desc',
    key: 'id',
  },
  taskPage: 0,
  taskStatus: 'normal',
  taskType: 'camera',
  viewType: 'card',
};

const PageContext = createContext<PageContextStore>(PageContextDefaultState);

export default PageContext;

export function usePageContext(): PageContextStore {
  const {
    detectionPage,
    detectionViewType,
    editModelRepositoryId,
    isEditModelRepositoryModalOpen,
    scenarioId,
    selectModelRepositoryId,
    setDetectionPage,
    setDetectionViewType,
    setEditModelRepositoryId,
    setIsEditModelRepositoryModalOpen,
    setScenarioId,
    setSelectModelRepositoryId,
    setSortConfig,
    setTaskPage,
    setTaskStatus,
    setTaskType,
    setViewType,
    sortConfig,
    taskPage,
    taskStatus,
    taskType,
    viewType,
  } = useContext(PageContext);

  return {
    detectionPage,
    detectionViewType,
    editModelRepositoryId,
    isEditModelRepositoryModalOpen,
    scenarioId,
    selectModelRepositoryId,
    setDetectionPage,
    setDetectionViewType,
    setEditModelRepositoryId,
    setIsEditModelRepositoryModalOpen,
    setScenarioId,
    setSelectModelRepositoryId,
    setSortConfig,
    setTaskPage,
    setTaskStatus,
    setTaskType,
    setViewType,
    sortConfig,
    taskPage,
    taskStatus,
    taskType,
    viewType,
  };
}

export function usePageContextValues(): PageContextStore {
  const [searchParams] = useSearchParams();

  const [taskPage, setTaskPage] = useState<number>(1);
  const [detectionPage, setDetectionPage] = useState(1);
  const [taskType, setTaskType] = useState<ObservTaskType>('camera');
  const [viewType, setViewType] = useState<TaskViewType>('card');
  const [detectionViewType, setDetectionViewType] = useState<DetectionViewType>('scenario');
  const [taskStatus, setTaskStatus] = useState<TaskStatus>('normal');
  const [scenarioId, setScenarioId] = useState(ALL_SCENARIO_ID);
  const [sortConfig, setSortConfig] = useState<SortConfig<TaskListItem>>({
    direction: 'desc',
    key: 'id',
  });
  const [editModelRepositoryId, setEditModelRepositoryId] = useState<number | null>(null);
  const [isEditModelRepositoryModalOpen, setIsEditModelRepositoryModalOpen] = useState(false);
  const [selectModelRepositoryId, setSelectModelRepositoryId] = useState<number | null>(null);

  useEffect(() => {
    setTaskPage(1);
  }, [taskType]);

  useEffect(() => {
    setScenarioId(ALL_SCENARIO_ID);
  }, [taskType]);

  useEffect(() => {
    if (!searchParams.get('taskPage')) return;

    setTaskPage(Number(searchParams.get('taskPage')));
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('taskType')) return;

    setTaskType(searchParams.get('taskType') as ObservTaskType);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('viewType')) return;

    setViewType(searchParams.get('viewType') as TaskViewType);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('taskStatus')) return;

    setTaskStatus(searchParams.get('taskStatus') as TaskStatus);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('detectionPage')) return;

    setDetectionPage(Number(searchParams.get('detectionPage')));
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('detectionViewType')) return;

    setDetectionViewType(searchParams.get('detectionViewType') as DetectionViewType);
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('scenarioId')) return;

    setScenarioId(Number(searchParams.get('scenarioId')));
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('key') || !searchParams.get('direction')) return;

    setSortConfig({
      direction: searchParams.get('direction') as OrderByDirection,
      key: searchParams.get('key') as keyof TaskListItem,
    });
  }, [searchParams]);

  const contextValues = useMemo(
    () => ({
      detectionPage,
      detectionViewType,
      editModelRepositoryId,
      isEditModelRepositoryModalOpen,
      scenarioId,
      selectModelRepositoryId,
      setDetectionPage,
      setDetectionViewType,
      setEditModelRepositoryId,
      setIsEditModelRepositoryModalOpen,
      setScenarioId,
      setSelectModelRepositoryId,
      setSortConfig,
      setTaskPage,
      setTaskStatus,
      setTaskType,
      setViewType,
      sortConfig,
      taskPage,
      taskStatus,
      taskType,
      viewType,
    }),
    [
      taskPage,
      setTaskPage,
      setTaskType,
      taskType,
      viewType,
      setViewType,
      detectionViewType,
      setDetectionViewType,
      detectionPage,
      setDetectionPage,
      taskStatus,
      setTaskStatus,
      scenarioId,
      setScenarioId,
      setSortConfig,
      sortConfig,
      editModelRepositoryId,
      setEditModelRepositoryId,
      isEditModelRepositoryModalOpen,
      setIsEditModelRepositoryModalOpen,
      selectModelRepositoryId,
      setSelectModelRepositoryId,
    ],
  );

  return contextValues;
}

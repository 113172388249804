import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getAccessToken } from 'src/shared/services/auth0/auth0Client';
import { useLangContext } from 'src/shared/services/store/LangContext';
import { Permission, PermissionRole } from '../../domain/model/permission';
import { Profile } from '../../domain/model/user';
import PERMISSION_INIT_DATA from '../../domain/permissionInitData';
import { PROFILE_INIT_DATA } from '../../domain/placeholderData';
import { usePermissionQuery, useUserProfileQuery } from '../query/userQuery';

interface UserContextStore {
  isLinker: boolean;
  isLoading: boolean;
  permission: Permission;
  token: string | undefined;
  userProfile: Profile;
}

export const userContextDefaultState: UserContextStore = {
  isLinker: false,
  isLoading: false,
  permission: PERMISSION_INIT_DATA[0].permission,
  token: '',
  userProfile: PROFILE_INIT_DATA,
};

export const UserContext = createContext<UserContextStore>(userContextDefaultState);

export function useUserContext(): UserContextStore {
  const { isLinker, isLoading, permission, token, userProfile } = useContext(UserContext);

  return { isLinker, isLoading, permission, token, userProfile };
}

export function useUserContextValues(): UserContextStore {
  const [token, setToken] = useState<string | undefined>(undefined);
  const { data: permissionList, isFetching: isLoading } = usePermissionQuery();
  const { data: userProfile } = useUserProfileQuery();
  const { setLang } = useLangContext();

  useEffect(() => {
    async function getToken(): Promise<void> {
      const auth0Token = await getAccessToken();

      setToken(auth0Token);
    }

    getToken();
  }, []);

  useEffect(() => {
    setLang(userProfile.language);
  }, [userProfile, setLang]);

  const isLinker = [PermissionRole.Owner, PermissionRole.Admin].includes(userProfile.role);

  const contextValues = useMemo(
    () => ({
      isLinker,
      isLoading,
      permission:
        permissionList.find(permission => permission.id === userProfile.role)?.permission ??
        PERMISSION_INIT_DATA[0].permission,
      token,
      userProfile,
    }),
    [isLinker, permissionList, isLoading, userProfile, token],
  );

  return contextValues;
}

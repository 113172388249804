import useTranslation from 'src/shared/hooks/useTranslation';
import generalMessages from 'src/shared/i18n/generalMessages';
import { LINKER_VERSION_LINK } from 'src/shared/libs/constants/links';

function ExceptionFooter(): JSX.Element {
  const { format } = useTranslation();

  return (
    <footer className="mx-auto w-full max-w-7xl shrink-0 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center space-x-4">
        <a className="text-sm font-medium text-gray-500 hover:text-gray-600" href={LINKER_VERSION_LINK}>
          {format(generalMessages, 'observDocumentation')}
        </a>
        <span aria-hidden="true" className="inline-block border-l border-gray-300" />
        <a className="text-sm font-medium text-gray-500 hover:text-gray-600" href={LINKER_VERSION_LINK}>
          {format(generalMessages, 'linkerVisionWebsite')}
        </a>
      </nav>
    </footer>
  );
}

export default ExceptionFooter;

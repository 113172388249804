import { OffsetAndLimit } from 'src/shared/libs/model/offsetAndLimit';
import { PageResponse } from 'src/shared/libs/model/pagesResponse';
import { TableData } from 'src/shared/libs/model/tableData';
import { apiGet } from 'src/shared/services/api/apiBase';
import { User } from '../../domain/model/user';
import { getUserFromDto } from './getUserFromApi';
import { UserDto } from './userDto';

export default async function getUserListFromApi({ limit, offset }: OffsetAndLimit): Promise<TableData<User>> {
  const { count, results } = await apiGet<PageResponse<UserDto>>('auth/users', {
    limit,
    offset,
    ordering: 'organization,id',
  });

  return { rows: results.map(getUserFromDto), totalRows: count };
}

import { ComponentType, lazy as originalLazy, LazyExoticComponent } from 'react';

type ImportComponent = () => Promise<{ default: ComponentType }>;

export const lazy = (importComponent: ImportComponent, moduleName: string): LazyExoticComponent<ComponentType> =>
  originalLazy((async () => {
    const isPageHasBeenForceRefreshed = JSON.parse(
      sessionStorage.getItem(`${moduleName}-has-been-force-refreshed`) ?? 'false',
    );

    try {
      const component = await importComponent();

      sessionStorage.setItem(`${moduleName}-has-been-force-refreshed`, 'false');

      return component;
    } catch (error) {
      if (!isPageHasBeenForceRefreshed) {
        sessionStorage.setItem(`${moduleName}-has-been-force-refreshed`, 'true');

        return window.location.reload();
      }

      throw error;
    }
  }) as ImportComponent);

export default lazy;

import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppPage from 'src/layout/AppPage';
import ErrorBoundary from 'src/shared/components/ErrorComponent/ErrorBoundary';
import NotFound from 'src/shared/components/NotFoundPage/NotFoundPage';
import Spin from 'src/shared/components/Spin';
import GlobalContextsProvider from './GlobalContextsProvider';
import { useProtectedPages, publicPages } from './pageInfo';

function RootRoutes(): JSX.Element {
  const protectedPages = useProtectedPages();

  return (
    <Suspense fallback={<Spin />}>
      <Routes>
        {publicPages.map(({ element: Component, path }) => (
          <Route element={<Component />} key={path} path={path} />
        ))}
        <Route
          element={
            <GlobalContextsProvider>
              <AppPage />
            </GlobalContextsProvider>
          }
        >
          {protectedPages.map(({ component: Component, path }) => (
            <Route
              element={
                <Suspense fallback={<Spin />}>
                  <ErrorBoundary>
                    <Component />
                  </ErrorBoundary>
                </Suspense>
              }
              key={path}
              path={path}
            />
          ))}
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Suspense>
  );
}

export default RootRoutes;

export const TERMS_AND_CONDITIONS_LINK = 'https://www.linkervision.com/website-terms-and-conditions';

export const SALES_LINK = 'https://www.linkervision.com/sales';

export const LINKER_VERSION_LINK = 'https://www.linkervision.com/';

export const CONFIRM_CREATED_TASK_LINK = 'https://linkervision.gitbook.io/observ/observ-usage/usage-and-billing';

export const LINKER_GIT_BOOK_LINK = {
  ADD_EVENT: 'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events',
  ADD_SCENARIOS: 'https://linkervision.gitbook.io/observ/advance-feature/customizing-scenarios',
  AI_THRESHOLD: 'https://linkervision.gitbook.io/observ/ovserview/ai-confidence-score',
  CREATE_TASK: 'https://linkervision.gitbook.io/observ/ovserview/creating-your-first-task',
  EVENT_CARD_AND:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-and',
  EVENT_CARD_COUNTING:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-counting',
  EVENT_CARD_NOT:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-not',
  EVENT_CARD_OBJECT:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-object-card',
  EVENT_CARD_OBJECT_TAG:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-object-tag',
  EVENT_CARD_OR:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-or',
  EVENT_CARD_OVERLAP:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-overlap',
  EVENT_CARD_TRIGGER:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-trigger-card',
  EVENT_CARD_WITH:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-logic-gate/logic-gate-with',
  EVENT_CARD_ZONE:
    'https://linkervision.gitbook.io/observ/advance-feature/custom-detection-events/event-component-area-card',
  EVENT_PARAMETERS: 'https://linkervision.gitbook.io/observ/ovserview/event-parameters',
  EXPORT_DATA: 'https://linkervision.gitbook.io/observ/advance-feature/exporting-data-to-dataverse',
  IMAGE_COLLECTION: 'https://linkervision.gitbook.io/observ/ovserview/collecting-ai-data',
  MODEL_IMPORT: 'https://linkervision.gitbook.io/observ/advance-feature/managing-ai-models',
  SYSTEM_SETTING: 'https://linkervision.gitbook.io/observ/advance-feature/system-settings',
};

type LinkerPlatformPage = 'home' | 'user' | '';

export const getLinkerPlatformUrl = (page: LinkerPlatformPage): string => {
  const linkerPlatformUrl = process.env.REACT_APP_LINKER_PLATFORM_URL as string;

  switch (page) {
    case 'user':
      return `${linkerPlatformUrl}user`;
    case 'home':
      return `${linkerPlatformUrl}`;

    default:
      return linkerPlatformUrl;
  }
};

export type PermissionId = (typeof PermissionRole)[keyof typeof PermissionRole];
export type PermissionName = keyof typeof PermissionRole;

export enum PermissionRole {
  Owner = 99,
  'Site Admin' = 50,
  Admin = 25,
  Viewer = 1,
}

export type Permission = {
  analytics: {
    canUseFilter: boolean;
    canViewPage: boolean;
  };
  detection: {
    canEditEvent: boolean;
    canEditScenario: boolean;
    canViewPage: boolean;
  };
  history: {
    canUseFilter: boolean;
    canViewPage: boolean;
  };
  resource: {
    canAddCamera: boolean;
    canAddLocation: boolean;
    canAddVideo: boolean;
    canDeleteCamera: boolean;
    canDeleteLocation: boolean;
    canDeleteVideo: boolean;
    canEditCamera: boolean;
    canEditLocation: boolean;
    canEditVideo: boolean;
    canViewCamera: boolean;
    canViewLocation: boolean;
    canViewPage: boolean;
    canViewVideo: boolean;
  };
  systemManagement: {
    canViewPage: boolean;
    editModelDeployment: boolean;
    editStorageDay: boolean;
  };
  taskDashboard: {
    canAddGroupTask: boolean;
    canAddMultiCameraGroupTask: boolean;
    canAddTask: boolean;
    canAddVideoTask: boolean;
    canViewPage: boolean;
    canViewTask: boolean;
    canViewVideoTask: boolean;
  };
  taskDetail: {
    canDeleteTask: boolean;
    canPauseTask: boolean;
    canTerminateTask: boolean;
    canViewPage: boolean;
    canViewSetting: boolean;
    eventLog: {
      canEditNote: boolean;
      canEditStatus: boolean;
    };
    setting: {
      detection: {
        canEditAiThreshold: boolean;
        canEditObjectSize: boolean;
        canViewAiThreshold: boolean;
        canViewObjectSize: boolean;
      };
      event: {
        canEditTrigger: boolean;
        canViewTrigger: boolean;
      };
      task: {
        canCaptureSnapshot: boolean;
        canEditCamera: boolean;
        canEditImageCollection: boolean;
        canEditLocation: boolean;
        canEditNotification: boolean;
        canEditROI: boolean;
        canEditTaskGroup: boolean;
        canEditTaskName: boolean;
        canEditVideoRecording: boolean;
        canEditZone: boolean;
        canRecordVideo: boolean;
        canUseAIConfidence: boolean;
        canViewImageCollection: boolean;
      };
    };
  };
  userManagement: {
    canAddOrganization: boolean;
    canAddUser: boolean;
    canChangeOrganizationMember: boolean;
    canDeleteUser: boolean;
    canEditOrganization: boolean;
    canEditUser: boolean;
    canViewPage: boolean;
  };
  vehicleAnalytics: {
    canUseFilter: boolean;
    canViewPage: boolean;
  };
  vehicleDetection: {
    canAddDataSet: boolean;
    canDeployModel: boolean;
    canExportDataSet: boolean;
    canImportModel: boolean;
    canUseFilter: boolean;
    canViewDataSet: boolean;
    canViewModelRepo: boolean;
    canViewPage: boolean;
  };
  vehicleHistory: {
    canDownloadEvent: boolean;
    canUpdateEventLog: boolean;
    canUseFilter: boolean;
    canViewPage: boolean;
  };
  vehicleSystemManagement: {
    canUseAnomaly: boolean;
    canUseFilter: boolean;
    canViewPage: boolean;
  };
  vehicleTask: {
    canDeleteTask: boolean;
    canPauseTask: boolean;
    canResumeTask: boolean;
    canUseFilter: boolean;
    canViewPage: boolean;
  };
};

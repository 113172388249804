import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import auth0Client from './auth0Client';

export default function RequireAuth({ children }: { children: JSX.Element }): JSX.Element | null {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    auth0Client.getAccessTokenSilently = getAccessTokenSilently;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    auth0Client.logout = (): any => ({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    return () => {
      auth0Client.getAccessTokenSilently = null;
      auth0Client.logout = null;
    };
  }, []);

  if (isLoading) return null;

  if (!isAuthenticated) {
    loginWithRedirect();

    return null;
  }

  return children;
}

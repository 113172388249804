import { useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AccessDenied from 'src/shared/components/AccessDenied/AccessDenied';
import UnknownErrorModal from 'src/shared/components/ErrorComponent/UnknownErrorModal';
import { ReactProps } from 'src/shared/libs/model/react';
import { ResponseError } from '../api/checkApiError';

export default function QueryClientWrapper({ children }: ReactProps): JSX.Element {
  const [error, setError] = useState<ResponseError | null>(null);

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        mutations: {
          onError: (responseError): void => {
            setError(responseError as ResponseError);
          },
        },
        queries: {
          onError: (responseError): void => {
            setError(responseError as ResponseError);
          },
          useErrorBoundary: true,
        },
      },
    }),
  );

  if (error?.status === 403) return <AccessDenied />;

  return (
    <QueryClientProvider client={queryClient.current}>
      {children}
      {error && <UnknownErrorModal errorInfo={error.toString()} onRequestClose={(): void => setError(null)} />}
    </QueryClientProvider>
  );
}

import { HomeSetting } from 'src/modules/user/domain/model/user';
import { ProtectedPathname, PageName } from './model';
import { useProtectedPages } from './pageInfo';

type PageNameMap = Record<ProtectedPathname, PageName>;

type PageIconMap = Record<ProtectedPathname, JSX.Element>;

type PageSystemMap = Record<ProtectedPathname, HomeSetting[]>;

type UsePageHook = {
  pageIconMap: PageIconMap;
  pageNameMap: PageNameMap;
  pageSystemMap: PageSystemMap;
};

function usePage(): UsePageHook {
  const protectedPages = useProtectedPages();

  const pageNameMap = protectedPages.reduce((acc, { name, path }) => ({ ...acc, [path]: name }), {} as PageNameMap);

  const pageIconMap = protectedPages.reduce((acc, { icon, path }) => ({ ...acc, [path]: icon }), {} as PageIconMap);

  const pageSystemMap = protectedPages.reduce(
    (acc, { path, system }) => ({ ...acc, [path]: system }),
    {} as PageSystemMap,
  );

  return {
    pageIconMap,
    pageNameMap,
    pageSystemMap,
  };
}

export default usePage;

import { apiPatchFormData } from 'src/shared/services/api/apiBase';
import { HomeSettingForm, LanguageForm, TimezoneForm } from '../../domain/model/user';
import { PatchMeDto } from './userDto';

export type PatchMeFormType = TimezoneForm | LanguageForm | HomeSettingForm;

export function getUserDto<T extends PatchMeFormType>(form: T): PatchMeDto {
  return {
    ...('timezone' in form && { timezone: form.timezone }),
    ...('language' in form && { language: form.language }),
    ...('preview' in form && { home_setting: form.preview }),
  };
}

export default async function patchMeApi<T extends PatchMeFormType>(form: T): Promise<void> {
  const dto = getUserDto<T>(form);

  await apiPatchFormData<PatchMeDto>('auth/users/me', dto);
}

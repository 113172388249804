import { apiGet } from 'src/shared/services/api/apiBase';
import PLAN_TYPE_DTO_TO_PLAN_TYPE_MAP from '../../domain/model/planMap';
import { Profile } from '../../domain/model/user';
import { ProfileDto } from './userDto';

export function getMeFromDto({
  active: isActive,
  avatar,
  email,
  first_name: firstName,
  has_unread: hasUnread,
  home_setting,
  id,
  language,
  last_name: lastName,
  organization,
  phone,
  role,
  timezone,
  workspace,
}: ProfileDto): Profile {
  return {
    avatar,
    email,
    firstName,
    hasUnread,
    homeSetting: home_setting,
    id,
    isActive,
    language,
    lastName,
    organization,
    phone,
    role,
    timezone,
    workspace: {
      name: workspace.name,
      planType: PLAN_TYPE_DTO_TO_PLAN_TYPE_MAP[workspace.plan_type],
      providerType: workspace.provider_type,
      tenantId: workspace.tenant_id,
      workspaceId: workspace.workspace_id,
    },
  };
}

export default async function getUserMeFromApi(): Promise<Profile> {
  const result = await apiGet<ProfileDto>('auth/users/me');

  return getMeFromDto(result);
}

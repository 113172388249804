import { useEffect, useRef, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useUserContext } from 'src/modules/user/services/store/UserContext';
import { JsonObject } from 'src/shared/libs/model/jsonObject';
import { useWorkspaceContext } from '../store/WorkspaceContext';

interface SocketHook<T> {
  message: T | undefined;
  send: (message: Record<string, JsonObject>) => void;
}

export default function useSocket<T>(socketUrl: string): SocketHook<T> {
  const { token } = useUserContext();
  const { workSpaceId } = useWorkspaceContext();
  const [message, setMessage] = useState<T>();

  const webSocket = useRef<ReconnectingWebSocket>();

  useEffect(() => {
    if (!token || !workSpaceId) return;

    const url = `${socketUrl}?token=${token}&serviceId=${workSpaceId}`;

    const socket = new ReconnectingWebSocket(url);

    webSocket.current = socket;

    return (): void => socket.close();
  }, [socketUrl, token, workSpaceId]);

  useEffect(() => {
    if (!webSocket.current) return;

    webSocket.current.onmessage = (event: MessageEvent): void => {
      if (event.data) setMessage(JSON.parse(event.data));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocket.current]);

  const send = (msg: Record<string, JsonObject>): void => {
    webSocket.current?.send(JSON.stringify(msg));
  };

  return { message, send };
}

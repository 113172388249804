import {
  DefinedUseQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import DEFAULT_ROWS_PER_PAGE from 'src/shared/libs/constants/defaultRowsPerPage';
import DEFAULT_TABLE_DATA from 'src/shared/libs/constants/defaultTableData';
import { OffsetAndLimit } from 'src/shared/libs/model/offsetAndLimit';
import { TableData } from 'src/shared/libs/model/tableData';
import { ErrorCode } from 'src/shared/services/api/apiErrorCode';
import { ResponseError } from 'src/shared/services/api/checkApiError';
import {
  User,
  UserPermission,
  EditUserFormType,
  LinkerPlatformUser,
  AddUser,
  Profile,
  Layout,
} from '../../domain/model/user';
import PERMISSION_INIT_DATA from '../../domain/permissionInitData';
import { USER_INIT_DATA, PROFILE_INIT_DATA, LAYOUT_INIT_DATA } from '../../domain/placeholderData';
import addUserToApi from '../apis/addUserToApi';
import deleteUserToApi from '../apis/deleteUserToApi';
import getLayoutFromApi from '../apis/getLayoutFromApi';
import getLinkerPlatformUserListFromApi from '../apis/getLinkerPlatformUserListFromApi';
import getPermissionFromApi from '../apis/getPermissionFromApi';
import getUserFromApi from '../apis/getUserFromApi';
import getUserListFromApi from '../apis/getUserListFromApi';
import getUserMeFromApi from '../apis/getUserMeFromApi';
import patchMeApi, { PatchMeFormType } from '../apis/patchMeApi';
import patchUserToApi from '../apis/patchUserToApi';
import { UpdateMeErrorMessageDto } from '../apis/userDto';

const userMeQueryName = 'userMe';
const userListQueryName = 'userList';
const linkerUserListQueryName = 'linkerUserList';
const userDetailQuery = 'userDetail';
const permissionQueryName = 'permission';

export const layoutQueryName = 'layout';

export function useUserProfileQuery(): DefinedUseQueryResult<Profile> {
  return useQuery([userMeQueryName], () => getUserMeFromApi(), {
    initialData: PROFILE_INIT_DATA,
    refetchOnWindowFocus: false,
  });
}

export function useUsersQuery({
  limit = DEFAULT_ROWS_PER_PAGE,
  offset = 0,
}: OffsetAndLimit): DefinedUseQueryResult<TableData<User>> {
  return useQuery([userListQueryName, { limit, offset }], () => getUserListFromApi({ limit, offset }), {
    initialData: DEFAULT_TABLE_DATA,
    refetchOnWindowFocus: false,
  });
}

export function useLinkerPlatformUsersQuery(email?: string): DefinedUseQueryResult<LinkerPlatformUser[]> {
  return useQuery([linkerUserListQueryName, { email }], () => getLinkerPlatformUserListFromApi(email), {
    initialData: [],
    refetchOnWindowFocus: false,
  });
}

export function usePermissionQuery(): DefinedUseQueryResult<UserPermission[]> {
  return useQuery([permissionQueryName], () => getPermissionFromApi(), {
    initialData: PERMISSION_INIT_DATA,
    refetchOnWindowFocus: false,
  });
}

export function useUserDetailQuery(id: number): UseQueryResult<User> {
  return useQuery([userDetailQuery, { id }], () => getUserFromApi(id), {
    placeholderData: USER_INIT_DATA,
  });
}

export function usePatchUserMutation(
  id: number,
  onSuccess: () => void,
  onError: () => void,
): UseMutationResult<void, ResponseError, EditUserFormType> {
  const queryClient = useQueryClient();

  return useMutation(data => patchUserToApi(id, data), {
    onError: () => {
      onError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries([userListQueryName]);
      onSuccess();
    },
  });
}

export function useUpdateMeMutation(
  onSuccess?: () => void,
  onError?: () => void,
): UseMutationResult<void, ResponseError<UpdateMeErrorMessageDto>, PatchMeFormType> {
  const queryClient = useQueryClient();

  return useMutation(form => patchMeApi(form), {
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([userMeQueryName], { stale: true });
      }, 500);
      onSuccess?.();
    },
  });
}

export function useDeleteUserMutation(onSuccess: () => void): UseMutationResult<void, void, number> {
  const queryClient = useQueryClient();

  return useMutation(userId => deleteUserToApi(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([userListQueryName]);
      onSuccess();
    },
  });
}

type ErrorEmailsResponse = ResponseError<{ email: { code: ErrorCode; err_msg: string }[] }>;

export function useAddUserMutation(
  onSuccess: () => void,
  onError: (emailIndexList: number[]) => void,
): UseMutationResult<void, ErrorEmailsResponse, AddUser[]> {
  const queryClient = useQueryClient();

  return useMutation((users: AddUser[]) => addUserToApi(users), {
    onError: error => {
      if (error.fields?.email) {
        const invalidateUser = error.fields?.email.map(({ err_msg }) => err_msg); // err_msg will be email.

        const {
          request: { body },
        } = error;

        const emailIndexArray = invalidateUser.map(email =>
          (body as AddUser[]).findIndex(user => user.email === email),
        );

        onError?.(emailIndexArray);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries([userListQueryName]);
      onSuccess();
    },
  });
}

export function useLayoutQuery(): DefinedUseQueryResult<Layout> {
  return useQuery([layoutQueryName], () => getLayoutFromApi(), {
    initialData: LAYOUT_INIT_DATA,
    refetchOnWindowFocus: false,
  });
}

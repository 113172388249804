import { Loader } from 'visionai-baseui';

function Spin(): JSX.Element {
  return (
    <div className="absolute left-0 top-0 z-[9999] flex h-full w-full flex-row bg-white align-middle opacity-50">
      <Loader message="" />
    </div>
  );
}

export default Spin;

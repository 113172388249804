import { defineMessages } from 'react-intl';

const PREFIX = 'Layout.';

export default defineMessages({
  contactSupport: {
    defaultMessage: 'Contact support',
    id: `${PREFIX}contactSupport`,
  },
  status: {
    defaultMessage: 'Status',
    id: `${PREFIX}Status`,
  },
  twitter: {
    defaultMessage: 'Twitter',
    id: `${PREFIX}twitter`,
  },
});

type LocalStorageKey = 'observ_workspace_id' | 'observ_home_setting';

export const PREFIX = 'observ_';

export const WORKSPACE_ID_KEY: LocalStorageKey = `${PREFIX}workspace_id`;

export const HOME_SETTING_KEY: LocalStorageKey = `${PREFIX}home_setting`;

export const getLocalStorage = (key: LocalStorageKey): string | null => localStorage.getItem(key);

export const setLocalStorage = (key: LocalStorageKey, value: string): string | void => localStorage.setItem(key, value);

export const removeLocalStorage = (key: LocalStorageKey): string | void => localStorage.removeItem(key);

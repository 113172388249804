import type { useAuth0 } from '@auth0/auth0-react';

const auth0Client: {
  getAccessTokenSilently: ReturnType<typeof useAuth0>['getAccessTokenSilently'] | null;
  logout: (() => void) | null;
} = {
  getAccessTokenSilently: null,
  logout: null,
};

export async function getAccessToken(): Promise<string | undefined> {
  try {
    return await auth0Client.getAccessTokenSilently?.();
  } catch (error) {
    auth0Client.logout?.();

    throw error;
  }
}

export default auth0Client;

import { getLinkerPlatformUrl } from 'src/shared/libs/constants/links';
import { JsonObject } from '../../libs/model/jsonObject';
import { ErrorCode } from './apiErrorCode';

type HttpRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface RequestPayload {
  body?: JsonObject | FormData;
  method: HttpRequestMethod;
  url: string;
}

export type ErrorType = {
  code: ErrorCode;
  err_msg: string;
};

interface ResponseErrorObject<T = Record<string, unknown>> {
  detail: string;
  errors: string;
  rest?: T;
  status: number;
  title: string;
  type: string;
}

export class ResponseError<T = undefined> extends Error {
  public readonly detail: string;

  public readonly title: string;

  public readonly status: number;

  public readonly request: RequestPayload;

  public readonly type: string;

  public readonly fields?: T;

  public constructor(errorResponse: ResponseErrorObject<T>, request: RequestPayload, options?: ErrorOptions) {
    const { detail, errors, status, title, type, ...rest } = errorResponse;

    super(type, options);

    this.type = type;
    this.detail = detail;
    this.title = title;
    this.status = status;
    this.type = type;
    this.fields = rest as T;
    this.request = request;
  }

  toString(): string {
    return [
      `${this.name}: ${this.message}`,
      `Detail: ${this.detail}`,
      `Status: ${this.status}`,
      `Fields: ${JSON.stringify(this.fields)}`,
      `Request Url: ${this.request.url}`,
      `Request Method: ${this.request.method}`,
      `Request Body: ${JSON.stringify(this.request.body ?? null)}`,
    ].join('\n');
  }
}

const EXCEPT_UNAUTHORIZED_API_PATH = [
  'api/dataverse/authenticate',
  'api/export-dataverse',
  'api/ml-models',
  'api/vehicle-ml-models',
];

export default async function checkApiError(response: Response, request: RequestPayload): Promise<void> {
  const errorUrl = response.url;

  if (!response.ok) {
    const errorResponse: ResponseErrorObject = await response.json();
    const { status } = errorResponse;

    if (status === 401 && EXCEPT_UNAUTHORIZED_API_PATH.some(path => errorUrl.includes(path))) {
      throw new ResponseError(errorResponse, request);
    }

    if (status === 401) {
      window.location.replace(getLinkerPlatformUrl('home'));
    }

    if (status === 404) {
      window.location.href = `${process.env.PUBLIC_URL}/page-not-found`;
    }

    throw new ResponseError(errorResponse, request);
  }
}

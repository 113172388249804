import { ReactNode } from 'react';
import { ChatIcon, RefreshIcon, DocumentDuplicateIcon } from '@heroicons/react/solid';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ErrorBoundary as ErrorBoundaryIcon } from 'src/images';
import AccessDenied from 'src/shared/components/AccessDenied/AccessDenied';
import useTranslation from 'src/shared/hooks/useTranslation';
import generalMessages from 'src/shared/i18n/generalMessages';
import { SALES_LINK } from 'src/shared/libs/constants/links';
import { ResponseError } from 'src/shared/services/api/checkApiError';
import { Button } from 'visionai-baseui';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export default function ErrorBoundary({ children }: ErrorBoundaryProps): JSX.Element {
  const { format } = useTranslation();

  const fallbackRender = ({ error }: FallbackProps): ReactNode => {
    if ((error as ResponseError).status === 403) return <AccessDenied />;

    return (
      <main className="mx-auto flex min-h-[calc(100vh-3rem)] grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-12">
          <div className="flex h-60 w-60 items-center justify-center rounded-full bg-gray-50">
            <img alt="" className="h-40 w-40" src={ErrorBoundaryIcon} />
          </div>
          <div className="text-sm text-gray-500">
            <p className="text-center text-2xl font-bold text-gray-900">
              {format(generalMessages, 'errorBoundaryTitle')}
            </p>
            <div className="mt-3 text-center">{format(generalMessages, 'errorBoundarySubtitle')}</div>
          </div>
          <ul className="list-disc text-sm text-gray-500">
            <li>{format(generalMessages, 'errorBoundaryTips1')}</li>
            <li>{format(generalMessages, 'errorBoundaryTips2')}</li>
            <li>{format(generalMessages, 'errorBoundaryTips3')}</li>
          </ul>
          <div className="w-[52.5rem] bg-gray-200 px-6 py-4">
            <div className="flex w-full justify-between">
              <h3 className="text-base font-medium leading-6 text-gray-600">
                {format(generalMessages, 'moreInformation')}
              </h3>
              <Button
                icon={<DocumentDuplicateIcon />}
                variant="link"
                onClick={(): void => {
                  navigator.clipboard.writeText(error);
                }}
              >
                {format(generalMessages, 'copy')}
              </Button>
            </div>
            <div>{error.toString()}</div>
          </div>
          <div className="flex space-x-2.5">
            <Button
              icon={<ChatIcon />}
              variant="white"
              onClick={(): Window | null => window.open(SALES_LINK, '_blank', 'noreferrer')}
            >
              {format(generalMessages, 'contactSupport')}
            </Button>
            <Button icon={<RefreshIcon />} variant="primary" onClick={(): void => window.location.reload()}>
              {format(generalMessages, 'refreshPage')}
            </Button>
          </div>
        </div>
      </main>
    );
  };

  return (
    <ReactErrorBoundary
      fallbackRender={fallbackRender}
      onError={(error: Error, info: { componentStack: string }): void => {
        // eslint-disable-next-line no-console
        console.log(error, info);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

import { useMemo } from 'react';
import { matchRoutes, Outlet, useLocation } from 'react-router-dom';
import { UserPermissionsKey } from 'src/modules/user/domain/model/user';
import { useUserContext } from 'src/modules/user/services/store/UserContext';
import { useProtectedPages } from 'src/router/pageInfo';
import AccessDeniedPage from 'src/shared/components/AccessDenied/AccessDenied';
import Spin from 'src/shared/components/Spin';
import { useWorkspaceContext } from 'src/shared/services/store/WorkspaceContext';
import Footer from './Footer';
import Navigation from './Navigation';

function AppPage(): JSX.Element {
  const location = useLocation();
  const protectedPages = useProtectedPages();
  const [{ route }] = matchRoutes(protectedPages, location) ?? [{}];
  const { permission } = useUserContext();
  const { homeSetting } = useWorkspaceContext();

  const isAuth = useMemo(() => {
    if (!route || route.requiredPermissions === '') {
      return true;
    }

    return permission[route?.requiredPermissions as UserPermissionsKey].canViewPage;
  }, [permission, route]);

  if (!isAuth) {
    return <AccessDeniedPage />;
  }

  const isShowFooter = route?.path === '/get-started';

  return (
    <div className="flex h-screen w-screen flex-row bg-gray-100">
      <Navigation />
      <div className="h-screen w-[calc(100vw-4rem)] overflow-auto">
        {!homeSetting ? (
          <Spin />
        ) : (
          <>
            <main className="min-h-[calc(100vh-4rem)]">
              <Outlet />
            </main>
            {isShowFooter && (
              <div className="mt-4 flex h-[2rem] w-full flex-row items-center justify-center">
                <Footer />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AppPage;

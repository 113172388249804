import { apiGet } from 'src/shared/services/api/apiBase';
import { User } from '../../domain/model/user';
import { UserDto } from './userDto';

export function getUserFromDto({
  active: isActive,
  avatar,
  email,
  first_name: firstName,
  has_unread: hasUnread,
  home_setting,
  id,
  language,
  last_name: lastName,
  organization,
  phone,
  role,
  timezone,
}: UserDto): User {
  return {
    avatar,
    email,
    firstName,
    hasUnread,
    homeSetting: home_setting,
    id,
    isActive,
    language,
    lastName,
    organization,
    phone,
    role,
    timezone,
  };
}

async function getUserFromApi(id: number): Promise<User> {
  const result = await apiGet<UserDto>(`auth/users/${id}`);

  return getUserFromDto(result);
}

export default getUserFromApi;

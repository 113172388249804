import { apiPost } from 'src/shared/services/api/apiBase';
import { AddUser } from '../../domain/model/user';
import { AddUserDto } from './userDto';

function getUserDtoFromUser(users: AddUser[]): AddUserDto[] {
  return users.map(({ email, firstName, id, lastName, organization, role }) => ({
    email,
    first_name: firstName,
    id,
    last_name: lastName,
    organization,
    role,
  }));
}

export default async function addUserToApi(users: AddUser[]): Promise<void> {
  await apiPost('api/linker-platform/add-members', getUserDtoFromUser(users));
}

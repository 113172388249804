import { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

type UseTranslationHook = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: <T extends Record<string, MessageDescriptor>>(messages: T, key: keyof T, value?: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatDynamic: <T extends Record<string, MessageDescriptor>>(messages: T, key: string, value?: any) => string;
};

/**
 * Use `format` when the key is primitive string
 *
 * Use `formatDynamic` when the key is generated
 *
 * @example
 *
 * const { format, formatDynamic } = useTranslation()
 *
 * const text1 = format(messages, 'yes')
 * const text2 = formatDynamic(messages, `scenario${scenario.id}`)
 */
export default function useTranslation(): UseTranslationHook {
  const { formatMessage } = useIntl();

  const format = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends Record<string, MessageDescriptor>>(messages: T, key: keyof T, value?: any): string => {
      return formatMessage(messages[key], value);
    },
    [formatMessage],
  );

  const formatDynamic = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends Record<string, MessageDescriptor>>(messages: T, key: string, value?: any): string => {
      if (Object.prototype.hasOwnProperty.call(messages, key)) return formatMessage(messages[key], value);

      return key;
    },
    [formatMessage],
  );

  return { format, formatDynamic };
}

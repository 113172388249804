import { DocumentDuplicateIcon } from '@heroicons/react/solid';
import { ErrorBoundary as ErrorBoundaryIcon } from 'src/images';
import { SALES_LINK } from 'src/shared/libs/constants/links';
import { Button, Modal, ModalBody } from 'visionai-baseui';

interface Props {
  errorInfo: string;
  onRequestClose: () => void;
}

function UnknownErrorModal({ errorInfo, onRequestClose }: Props): JSX.Element {
  return (
    <Modal size="md" onRequestClose={onRequestClose}>
      <ModalBody>
        <div className="mt-12 flex flex-col items-center justify-center space-y-12">
          <div className="flex h-40 w-40 items-center justify-center rounded-full bg-gray-50">
            <img alt="" className="h-20 w-20" src={ErrorBoundaryIcon} />
          </div>
          <div className="text-sm text-gray-500">
            <p className="text-center text-2xl font-bold text-gray-900">Submission Error</p>
            <div className="mt-3 text-center">
              Sorry, there was an issue processing your form. We apologize for the inconvenience.
            </div>
          </div>
          <ul className="list-disc text-sm text-gray-500">
            <li>Please check your internet connection and try again.</li>
            <li>If the issue persists, wait a moment and submit once more.</li>
            <li>Reach out to our support team if you continue to face problems.</li>
          </ul>
          <div className="w-full rounded-md bg-gray-200 px-6 py-4">
            <div className="flex w-full justify-between">
              <h3 className="text-base font-medium leading-6 text-gray-600">More information</h3>
              <Button
                icon={<DocumentDuplicateIcon />}
                variant="link"
                onClick={(): void => {
                  navigator.clipboard.writeText(errorInfo);
                }}
              >
                Copy
              </Button>
            </div>
            <div className="mt-2 w-full whitespace-pre-wrap break-words text-sm font-normal leading-5 text-gray-500">
              {errorInfo}
            </div>
          </div>
          <div className="flex w-full space-x-2.5">
            <Button
              className="w-full"
              variant="white"
              onClick={(): Window | null => window.open(SALES_LINK, '_blank', 'noreferrer')}
            >
              Contact Support
            </Button>
            <Button className="w-full" variant="primary" onClick={onRequestClose}>
              Try Again
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default UnknownErrorModal;

import useTranslation from 'src/shared/hooks/useTranslation';
import layoutMessages from './messages';

type FooterItemProp = {
  name: string;
};

function FooterItem({ name }: FooterItemProp): JSX.Element {
  return <h6 className="mx-3 text-sm text-gray-500">{name}</h6>;
}

function FooterSplitLine(): JSX.Element {
  return <span className="text-gray-300">|</span>;
}

function Footer(): JSX.Element {
  const { format } = useTranslation();

  return (
    <>
      <FooterItem name={format(layoutMessages, 'contactSupport')} />
      <FooterSplitLine />
      <FooterItem name={format(layoutMessages, 'status')} />
      <FooterSplitLine />
      <FooterItem name={format(layoutMessages, 'twitter')} />
    </>
  );
}

export default Footer;

import { createContext, useContext, useMemo, useState } from 'react';
import { LanguageType } from 'src/modules/user/domain/model/user';

export interface LangContextStore {
  lang: LanguageType;
  setLang: (lang: LanguageType) => void;
}

export const LangContextDefaultState: LangContextStore = {
  lang: 'en',
  setLang: () => {},
};

const LangContext = createContext<LangContextStore>(LangContextDefaultState);

export default LangContext;

export function useLangContext(): LangContextStore {
  const { lang, setLang } = useContext(LangContext);

  return {
    lang,
    setLang,
  };
}

export function useLangContextValues(): LangContextStore {
  // It can get browser lang to set default value.
  const [lang, setLang] = useState<LanguageType>('en');

  const contextValues = useMemo(
    () => ({
      lang,
      setLang,
    }),
    [lang, setLang],
  );

  return contextValues;
}

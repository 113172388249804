import { LogoutIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { PageNotFound } from 'src/images';
import { ROOT_PATHNAME } from 'src/router/pageInfo';
import useTranslation from 'src/shared/hooks/useTranslation';
import generalMessages from 'src/shared/i18n/generalMessages';
import { Button } from 'visionai-baseui';
import ExceptionFooter from '../ExceptionFooter';

export default function NotFoundPage(): JSX.Element {
  const { format } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex h-[100vh] flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center space-y-12 px-4 sm:px-6 lg:px-8">
        <div className="flex shrink-0 justify-center">
          <img alt="" className="h-32 w-32" src={PageNotFound} />
        </div>
        <div className="text-center text-gray-500">
          <div className="text-center text-base text-blue-600">{format(generalMessages, 'observ')}</div>
          <div className="mt-2 text-center text-3xl font-extrabold text-gray-900">
            {format(generalMessages, 'pageNotFound')}
          </div>
          <div className="mt-5 whitespace-pre-line text-lg text-gray-500">
            {format(generalMessages, 'pageNotFoundDescription')}
          </div>
        </div>
        <div className="flex justify-center space-x-2.5">
          <Button icon={<LogoutIcon />} variant="primary" onClick={(): void => navigate(ROOT_PATHNAME.home)}>
            {format(generalMessages, 'returnToObservHome')}
          </Button>
        </div>
      </main>
      <ExceptionFooter />
    </div>
  );
}

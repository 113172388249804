import { apiGet } from 'src/shared/services/api/apiBase';
import { Layout } from '../../domain/model/user';
import { LayoutDto } from './userDto';

function getLayoutFromDto(data: LayoutDto): Layout {
  const { can_create, has_created, home_setting } = data;

  return {
    canCreate: {
      cameraTask: can_create.camera_task,
    },
    homeSetting: home_setting,
    isCreated: {
      hasCamera: has_created.camera,
      hasCameraEventLog: has_created.camera_eventlog,
      hasCameraTask: has_created.camera_task,
      hasComputeTarget: has_created.compute_target,
      hasDataSet: has_created.export_dataverse,
      hasGroupTask: has_created.group_task,
      hasModel: has_created.import_dataverse,
      hasVideo: has_created.video,
      hasVideoEventLog: has_created.video_eventlog,
      hasVideoTask: has_created.video_task,
    },
  };
}

async function getLayoutFromApi(): Promise<Layout> {
  const result = await apiGet<LayoutDto>('api/layout');

  return getLayoutFromDto(result);
}

export default getLayoutFromApi;

import en, { Locale } from 'dayjs/locale/en';
import zhCn from 'dayjs/locale/zh-cn';
import znTw from 'dayjs/locale/zh-tw';
import { LanguageType } from 'src/modules/user/domain/model/user';

const getLocale = (type: LanguageType): Locale => {
  switch (type) {
    case 'en':
      return en;
    case 'zh':
      return znTw;
    case 'zh-cn':
      return zhCn;

    default:
      return en;
  }
};

export default getLocale;
